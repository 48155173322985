import { FC, useEffect } from "react";
import { useNavigate } from "@remix-run/react";

import NotFoundIcon from "icons/404.svg?react";

import { watcher } from "store";

import { Pages } from "constants/pages";

import Button from "basics/button";

import globalClasses from "styles/classes.module.scss";
import classes from "./styles/classes.module.scss";

const NotFound: FC = () => {
  const navigate = useNavigate();

  useEffect(
    () =>
      watcher.startListening({
        predicate: (
          _action,
          { account: { data } },
          { account: { data: prevData } },
        ) => data?.email !== prevData?.email,
        effect: () => {
          navigate(`${window.location.pathname}${window.location.search}`, {
            replace: true,
            preventScrollReset: true,
          });
        },
      }),
    [],
  );

  return (
    <main className={globalClasses.textedMain}>
      <div className={classes.wrapper}>
        <div className={classes.centerWrapper}>
          <NotFoundIcon className={classes.notFoundIcon} />
          <h1 className={classes.title}>Page doesn't exist</h1>
          <div className={classes.description}>
            Please check URL carefully or start from the main page
          </div>
        </div>
        <Button
          className={classes.button}
          themeName="primary"
          size="lg"
          onClick={() => navigate(Pages.home)}
        >
          Go to main page
        </Button>
      </div>
    </main>
  );
};

export default NotFound;
